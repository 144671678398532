<template>
  <div class="avatar">
    <span
      v-if="noAvatar"
      class="avatar__no-avatar"
      :class="`bgcolor${colorIndex}`"
      :data-content="user.first_name[0]"/>
    <img
      v-else
      :src="avatarUrl"
      alt="Avatar"
      class="avatar__avatar">
  </div>
</template>

<script>
  export default {
    name: 'Avatar',
    props: {
      user: Object,
    },
    computed: {
      noAvatar() {
        return !this.avatarUrl || !!this.avatarUrl.match(/no_avatar/)
      },
      avatarUrl() {
        return this.user.photo && this.user.photo.replace('telegra.ph/file', 'comments.bot/userpic')
      },
      colorIndex() {
        return this.user && this.user.user_id % 9
      }
    },
  }
</script>

<style scoped lang="stylus">
  .bgcolor0
    background #e17076

  .bgcolor1
    background #faa774

  .bgcolor2
    background #a695e7

  .bgcolor3
    background #7bc862

  .bgcolor4
    background #6ec9cb

  .bgcolor5
    background #65aadd

  .bgcolor6
    background #ee7aae

  .bgcolor7
    background #6ec9cb

  .bgcolor8
    background #faa774

  .avatar
    width 40px
    height 40px

  .avatar, .avatar__no-avatar, .avatar__no-avatar:before, .avatar__avatar
    border-radius 50%

  .avatar__no-avatar
    display block
    position relative

    font-family Roboto, Arial, sans-serif
    font-weight 500
    font-size 20px

    width 100%
    height 100%
    line-height 41px

    font-style normal
    text-align center
    text-transform uppercase
    color #fff
    overflow hidden

    cursor pointer

    &:before
      content attr(data-content)
      position absolute
      top 0
      left 0
      margin 0 auto
      width 100%

  .avatar__avatar
    width 100%

    cursor pointer
</style>
