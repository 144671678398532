<template>
  <div class="header-layout">
    <Header/>
    <transition name="route-change-fade">
      <RouterView/>
    </transition>
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'

  export default {
    name: 'FixedHeaderLayout',
    components: {
      Header,
    }
  }
</script>

<style scoped lang="stylus">
  .header-layout
    padding-top 58px
    @apply flex flex-col flex-grow items-stretch relative
    overflow auto
    min-height 100vh
</style>